<template>
  <div>
    <el-dialog title="查看学生"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">

      <el-table border
                :stripe="true"
                highlight-current-row
                :data="arr"
                style="width: 100%">
        <el-table-column prop="grade_name"
                         align="center"
                         label="年级" />
        <el-table-column prop="subject_name"
                         align="center"
                         label="科目" />
        <el-table-column prop="truename"
                         align="center"
                         label="真实姓名" />
        <el-table-column prop="verifiedMobile"
                         align="center"
                         label="手机号" />

      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        grade_id: '',
      },
      special_class_id: '',
      page: 1,
      size: 10,
      totalElements: 0,
      dialogVisible: false,
      gradeList: [],
      tableData: [],
      arr: [],
    }
  },
  mounted () {
    // this.getList()
  },
  methods: {
    handleClose () {
      this.form = {
        grade_id: '',
        class_id: '',
      }
      this.arr = []
      this.tableData = []
      this.dialogVisible = false
    },
    // getList () {
    //   this.$http({
    //     url: '/api/v1/school/select_grade',
    //     method: 'get'
    //   }).then(res => {
    //     this.gradeList = res.data.list
    //   })
    // },
    initData () {
      this.tableData = []
      if (this.form.grade_id == '' || this.form.class_id == '') return
      this.$http({
        url: '/api/v1/special/search_student',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data
      })
    },
    getAlreadyHaveStudent (id) {
      this.$http({
        url: '/api/v1/special/class_student',
        method: 'get',
        params: {
          special_class_id: this.special_class_id
        }
      }).then(res => {
        this.arr = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.btn {
  text-align: center;
  margin-top: 40px;
}
</style>
